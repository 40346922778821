import { createSlice } from "@reduxjs/toolkit";

const activePage = createSlice({
    name:'activepage',
    initialState:{
        value:'home'
    },
    reducers:{
        setActivePage:(state,action)=>{
            state.value = action.payload;
        }
    }
})

export const {setActivePage} = activePage.actions;
export default activePage.reducer