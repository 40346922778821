import style from './Connectwithus.module.css';
export default function Connectwithus(){
    return(
        <div className={`${style.main} d-flex flex-column justify-content-center align-items-center gap-3`}>
            <h3>Connect with us</h3>
            <div className='col-6'>
                <span>Sit magna quis laborum nostrud. Sint cupidatat labore eiusmod velit dolor nisi enim. Voluptate elit magna dolor ea elit velit sit aliqua irure labore esse ea consectetur. Fugiat aute non cillum sit esse cupidatat magna in fugiat laborum duis veniam.</span>
            </div>
            <div className={`${style.connectwithusInput}`}>
                <input type='text'></input>
                <span>Connect</span>
            </div>
        </div>
    )
}