import style from './Home.module.css';
import Slider from 'react-slick';
import { Row,Col } from 'react-bootstrap';
import Item from '../Item/Item.component';
import { db } from '../../firebase';
import { collection,getDocs } from 'firebase/firestore';
import { useEffect } from 'react';
import Connectwithus from '../ConnectWithUs/Connectwithus.component';
import { useState } from 'react';
export default function Home(){
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, 
        autoplaySpeed: 2000, 
    };
    const [dashboardItems,setDashboardItems] = useState([]);
    const [productItems,setProductItems] = useState([]);
    useEffect(()=>{
        const fetchDashboard =async ()=>{
          getDocs(collection(db,'dashboard')).then((item)=>{
            setDashboardItems(item.docs.map(doc => ({
              ...doc.data() // Include the document data
          })));
          })
        }
        const fetchProducts =async ()=>{
            getDocs(collection(db,'products')).then((item)=>{
              setProductItems(item.docs.map(doc => ({
                ...doc.data() // Include the document data
            })));
            })
          }
        fetchProducts();
        fetchDashboard();
      },[])
    return(
        <div>
        <Row  className={`d-flex justify-content-center my-5 mx-2 gap-5 ${style.main}`} >
            <Col xs='12'  className='d-flex justify-content-center'>
                <Slider {...settings}  className={`${style.carouselSlider}`}> 
                  {dashboardItems.map((item)=>{
                    return (<div className={`${style.carouselimg}`}>
                        <img src={item.photos[0].downloadURL} alt={item.uid} />
                    </div>)
                  })}
                  
                </Slider>
            </Col>
            <Col xs='12' md='11'>
                <div className='d-flex justify-content-center flex-column'>
                    <h3>New Arrival</h3>
                    <hr></hr>
                    <div className={`${style.newArrivals}`}>
                        {
                          productItems.map((item,index)=>index<2?<Item item={item}></Item>:'')
                        }
                    </div>
                </div>
            </Col>
        </Row>
        <Connectwithus></Connectwithus>
        </div>
    )
}