import { IoMdClose } from "react-icons/io";
import style from "./Category.module.css";
import { useDispatch, useSelector } from 'react-redux';
import { toggleCategory } from "../../Reducers/CategoryToggler";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

export default function Category() {
  const cateogrySidebar = useSelector((state)=>state.category);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleCategoryInSmallDevice=()=>{
    dispatch(toggleCategory());
  }
  const [categories,setCategories] = useState([]);
  const updateActivePage = (e)=>{
    navigate('/products?category='+e.currentTarget.getAttribute('uid'));
  }
  useEffect(()=>{
    const fetchCategories =async ()=>{
      getDocs(collection(db,'category')).then((item)=>{
        setCategories(item.docs.map(doc => ({
          ...doc.data() // Include the document data
      })));
      })
    }
    fetchCategories();
  },[])
  return (
    <div className={`${style.categoryContainer} ${cateogrySidebar.value?style.expand:''} d-flex mx-lg-2 my-lg-3`}>
      <div
        className={`col-12 d-flex py-3 px-2 d-flex d-lg-none justify-content-end`}
      >
        <span onClick={toggleCategoryInSmallDevice}><IoMdClose/></span>
      </div>
      <ul className="col-12 col-lg-12">
        {
          categories.map((item)=>{
            return (<li>
              <div className={`${style.categoryItem} d-flex flex-column gap-2`} uid={item.uid} onClick={updateActivePage}>
                <img src={item.photos[0].downloadURL} alt={item.name}></img>
                <span>{item.name}</span>
              </div>
            </li>)
          })
        }
      </ul>
    </div>
  );
}
