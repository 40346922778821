import './App.css';
import Navbar from './Components/Navbar/Navbar.component';
import Dashboard from './Components/Dashboard/Dashboard.component';
import 'slick-carousel/slick/slick.css'
import {
  HashRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import 'slick-carousel/slick/slick-theme.css'
import Footer from './Components/Footer/Footer.component';
import Itemdetail from './Components/Itemdetail/itemdetail.component';
import Aboutus from './Components/Aboutus/Aboutus.component';
import Contactus from './Components/Contactus/Contactus.component';
import Shop from './Components/Shop/Shop.component';
function App() {
  const mainHome = (
    <div className="App d-flex flex-column justify-content-center align-items-center">
      <Navbar></Navbar>
      <Dashboard ></Dashboard>
      <Footer></Footer>
    </div>
  );

  const itemdetails =(<div className="App d-flex flex-column justify-content-center align-items-center">
    <Navbar></Navbar>
    <Itemdetail></Itemdetail>
    <Footer></Footer>
  </div>)

  const aboutus = (<div className="App d-flex flex-column justify-content-center align-items-center">
    <Navbar></Navbar>
    <Aboutus></Aboutus>
    <Footer></Footer>
  </div>)

  const contactus = ((<div className="App d-flex flex-column justify-content-center align-items-center">
    <Navbar></Navbar>
    <Contactus></Contactus>
    <Footer></Footer>
  </div>))

  const products = ((<div className="App d-flex flex-column justify-content-center align-items-center">
    <Navbar></Navbar>
    <Shop></Shop>
    <Footer></Footer>
  </div>))
  return (
    <div className="App d-flex flex-column justify-content-center align-items-center">
      <Router>
        <Routes>
          <Route path="" element={mainHome}></Route>
          <Route path="/item" element={itemdetails}>
          </Route>
          <Route path='/products' element={products}></Route>
          <Route path='/aboutus' element={aboutus}></Route>
          <Route path='/contactus' element={contactus}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
