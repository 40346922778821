// store.js
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import items from './Reducers/Items';
import Category from './Reducers/CategoryToggler';
import Activepage from './Reducers/Activepage';

// Create root reducer
const rootReducer = combineReducers({
   cartitem: items,
   category: Category,
   activepage: Activepage,
 });

// Create persist config
const persistConfig = {
  key: 'root',
  storage,
};

// Apply persistReducer to the rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store
const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
