import { createSlice } from "@reduxjs/toolkit";


const categorySlice= createSlice({
    name:'categoryToggler',
    initialState:{
        value:false
    },
    reducers:{
        toggleCategory:(state,action)=>{
            state.value = !state.value;
        }
    }
})

export const {toggleCategory} = categorySlice.actions;
export default categorySlice.reducer;