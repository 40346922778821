import { FaInstagram} from "react-icons/fa";
import { FaFacebook, FaLinkedin, FaXTwitter } from "react-icons/fa6";
import style from "./Footer.module.css";
export default function Footer() {
  return (
    <div className={`${style.aboutuscontainer} d-flex pt-3 pt-md-5 justify-content-around`}>
      <div className={`${style.connect} col-6 col-md-4`}>
        <h1>FASHION</h1>
        <span>
          Duis esse cupidatat officia id tempor pariatur voluptate excepteur id
          sunt nulla. Quis in deserunt cupidatat ullamco consectetur eiusmod.
          Culpa laboris ea esse ex officia aliquip in ea nisi minim.
        </span>
        <div className={`${style.socialIcon} d-flex gap-3 my-4`}>
          <a href="https://www.google.com" target="_blank" rel="noreferrer"><FaInstagram/></a>
          <a href="https://www.google.com" target="_blank" rel="noreferrer"><FaXTwitter /></a>
          <a href="https://www.google.com" target="_blank" rel="noreferrer"><FaLinkedin/></a>
          <a href="https://www.google.com" target="_blank" rel="noreferrer"><FaFacebook/></a>
        </div>
      </div>
      <div className={`${style.quicklinks} col-6 col-md-4`}>
        <span>Quick links</span>
        <ul>
          <li>About Us</li>
          <li>Contact Us</li>
        </ul>
      </div>
    </div>
  );
}
