import { useNavigate } from "react-router-dom";
import style from "./Item.module.css";
import {  useRef} from "react";

export default function Item({ item }) {
  // const [quantity, setQuantity] = useState(0);
  const itemRef = useRef();
  const navigate = useNavigate();
  // const itemQuantity = useSelector((state) => state.cartitem);
  // const addItem = () => {
  //   if (quantity >= 0) {
  //     itemRef.current.classList.remove("disabled");
  //   }
  //   setQuantity(quantity + 1);
  //   item.quantity = quantity+1;
  //   dispatch(addNewItem(item));
  // };
  // const removeItem = () => {
  //   if (quantity == 0) {
  //     itemRef.current.classList.add("disabled");
  //     return;
  //   }
  //   setQuantity(quantity - 1);
  //   dispatch(removeCartItem(item));
  // };
  // const roundToNearestHalf = Math.round(item.rating * 2) / 2;
  // let halfStar = roundToNearestHalf%1 || 0;
  // const fullStar = Math.round(roundToNearestHalf) || 0;
  // const ratingElements = Array.from({length:5},(_i,index)=>{
  //   if(index<fullStar){
  //     return <span><FaStar></FaStar></span>
  //   } else if(halfStar!=0){
  //     halfStar = 0;
  //     return <span><FaStarHalfAlt></FaStarHalfAlt></span>
  //   } else{
  //     return <span><FaRegStar></FaRegStar></span>
  //   }
  // })
  const handleItemClick = ()=>{
    navigate(`/item?uid=${item.uid}&referenceId=${item.referenceId||''}` )
  }
  // useEffect(() => {
  //   const items = itemQuantity.cartItems;
  //   const currentItem = items.filter((obj) => item.uid === obj.uid);
  //   if (
  //     currentItem &&
  //     currentItem.length > 0 &&
  //     currentItem[0].quantity !== quantity
  //   ) {
  //     setQuantity(currentItem[0].quantity);
  //     if (currentItem[0].quantity > 0) {
  //       itemRef.current.classList.remove("disabled");
  //     }
  //   }
  // }, []);
  return (
    <div
      ref={itemRef}
      onClick={handleItemClick}
      className={`${style.Item} d-flex flex-column align-items-center`}
    >
      <div>
        <img
          src={item.photos[0].downloadURL}
          alt={item.name}
        ></img>
      </div>
      <footer>
        {/* <div className={style.addItem}>
          <span
            className={`d-flex align-items-center ${quantity===0?'':'px-1'}`}
            onClick={quantity === 0 ? addItem : () => {}}
          >
            <button
              onClick={removeItem}
              className={quantity === 0 ? "d-none" : ""}
            >
              <RiSubtractFill></RiSubtractFill>
            </button>
            <span
              className={`${style.quantitySpan}`}
            >
              {quantity > 0 ? quantity : <IoMdAdd></IoMdAdd>}
            </span>
            <button
              onClick={addItem}
              className={quantity === 0 ? "d-none" : ""}
            >
              <IoMdAdd></IoMdAdd>
            </button>
          </span>
        </div> */}
        <div className={`${style.itemInfo} d-flex flex-column `}>
            <h3 className="col-10">{item.name}</h3>
            {/* <div className={`${style.itemRating} d-flex col-4`}>
              {ratingElements}
            </div> */}
            <span className="col-10">{item.description}</span>
            
        </div>
        
      </footer>
    </div>
  );
}
