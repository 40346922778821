import style from "./Shop.module.css";
import Item from "../Item/Item.component";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { collection,getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useState } from "react";
export default function Shop() {
  // const filterRef = useRef();
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category');
  const [items , setItems ] =useState([]);
  useEffect(()=>{
      const fetchProducts = async ()=>{
        const q = query(collection(db,'products'),where('category','==',category));
        try{
          const querySnapshot = await getDocs(q);
          const items = querySnapshot.docs.map((doc) => ({
            id: doc.id, 
            ...doc.data()
          }));
          setItems(items);
        }catch(e){
          console.log(e);
        }
      }
      fetchProducts(); 
  })
  return (
    <div className={`${style.shopcontainer} mt-3 mt-lg-0 d-flex flex-column align-items-center `}>
      {/* <div
        className={`${style.filtercontainer} my-3 d-flex flex-column align-items-center gap-2`}
      >
        <div className={`${style.filter} d-flex row `} ref={filterRef}>
          <header className="col-12">
            <span>Filter</span>
          </header>
          <div className={`${style.filteritems} col-12`}>
            <span className="d-flex flex-column gap-3">
              CATEGORY
              <fieldset className="d-flex gap-3">
                <span>
                  <input type="checkbox"></input> Men
                </span>
                <span>
                  <input type="checkbox"></input> Women
                </span>
              </fieldset>
            </span>
            <span className="d-flex flex-column gap-3">
              TYPE
              <fieldset className="d-flex  gap-3">
                <span>
                  <input type="checkbox"></input> Men
                </span>
                <span>
                  <input type="checkbox"></input> Women
                </span>
              </fieldset>
            </span>
          </div>
          <footer className="d-flex col-11 justify-content-end">
            <button>APPLY</button>
          </footer>
        </div>
      </div> */}
      <div className="d-flex flex-column align-items-center col-11 col-md-10">
        <span className={` col-12 px-3 pt-4`} style={{textAlign:'start',fontWeight:'bold',fontFamily:'sans-serif'}}>{items.length} Items Found</span> 
        <hr className="col-12 "></hr>
      </div>
      <div className={`${style.itemscontainer} col-11 col-md-10`}>
        {
        items.map((item,index)=>{
            return <Item key={item.id} item={item}></Item>;
        })
        }   
      </div>
    </div>
  );
}
