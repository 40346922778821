import style from './Aboutus.module.css';
export default function Aboutus(){
    return(
        <div className={`${style.aboutuscontainer} d-flex justify-content-center`}>
            <div className={`${style.aboutusicon} col-4`}>

            </div>
            <div className={`${style.ourstory} col-6 d-flex flex-column justify-content-center`}>
                <p>Our story starts with the name Bewakoof®.</p>
                <p>Society perceives Bewakoof as stupid. But what does society call Bewakoof? <br></br>Often, it’s anything different or anything that’s done differently.</p>
                <p>Often when people have done the right thing, without caring about what society thinks, they have been called Bewakoof. These are the people who have changed the world and made it a better place.</p>
            </div>
        </div>
    )
}