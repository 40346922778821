import { createSlice } from "@reduxjs/toolkit";

const items = createSlice({
    name:'items',
    initialState:{
        cartItems:[]
    },
    reducers:{
        addNewItem:(state,action)=>{
            const existingIndex = state.cartItems.findIndex((item)=>item.id === action.payload.id);
            if(existingIndex>-1){
                state.cartItems[existingIndex].quantity+=1;
            }else{
                state.cartItems.push({...action.payload,quantity:1});
            }
            
        },
        removeCartItem:(state,action)=>{
            const existingIndex = state.cartItems.findIndex((item)=>item.id === action.payload.id);
            if(state.cartItems[existingIndex].quantity>1){
                state.cartItems[existingIndex].quantity-=1;
            }else{
                state.cartItems.splice(existingIndex,1);
            }
        }
    }
})

export const {addNewItem,removeCartItem} = items.actions;
export default items.reducer;