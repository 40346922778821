import style from './itemdetail.module.css';
import Item from '../Item/Item.component';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { db } from '../../firebase';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
export default function Itemdetail(){
    const [searchParams] = useSearchParams();
    const itemUid =searchParams.get('uid');
    const referenceId  =searchParams.get('referenceId')
    const [item, setItem]= useState({
        uid:'',
        name:'',
        description:[],
        photos:[],
        productsizes:[],
    })
    
    const [relatedItem,setRelatedItem] = useState([]);
    const setDefaultImage = (event)=>{
        const i = event.currentTarget.getAttribute('index');
        setItem({...item,'photos':item.photos.map((item,index)=>index===parseInt(i)?{...item,isDefault:true}:{...item,isDefault:false})});
    }
    useEffect(()=>{
        const fetchItems = async ()=>{
            const itemObj = await getDoc(doc(db,'products',itemUid));
            const product = itemObj.data();
            if(product.photos.length>0){
                product.photos = product.photos.map((item,index)=>index===0?{...item,isDefault:true}:{...item,isDefault:false});
            }
            setItem({...product});
        }
        const fetchRelatedItem = async ()=>{
            const q = query(collection(db,'products'),where('refereceId','==',referenceId))
            try{
                const querySnapshot = await getDocs(q);
                const items = querySnapshot.docs.map((doc) => ({
                  id: doc.id, 
                  ...doc.data()
                }));
                setRelatedItem(items);
              }catch(e){
                console.log(e);
              }
        }
        fetchItems();
        fetchRelatedItem();
    })
    return(
        <div className={`${style.itemdetailcontainer} my-5`}>
            <div className={`d-flex flex-column flex-lg-row justify-content-center`}>
                <div className={`${style.itemimages} col-12 col-md-6 d-flex flex-column align-items-center align-items-md-start`}>
                   <div className='d-flex justify-content-center col-9 align-items-center'>
                        {
                            item.photos.map((item,index)=>{
                                return item.isDefault?<img index={index} key={index} alt={item.name} src={item.downloadURL}></img>:'';
                            })
                        }
                   </div>
                   <div className={`${style.otheriamges} col-5 gap-2 gap-lg-0 align-self-start`}>
                        {
                            item.photos.map((item,index)=>{
                                return !item.isDefault?<img key={index} index={index} alt={item.name} src={item.downloadURL} onClick={setDefaultImage}></img>:'';
                            })
                        }
                   </div>
                    <div className=' col-12 d-flex flex-wrap align-items-center  gap-2'>
                        <span style={{fontWeight:'bold',fontSize:'15px', textAlign:'start'}} className='col-12 col-md-2 mt-1 mt-lg-0'> Available sizes: </span>
                        <div className={`d-flex ${style.sizes} my-0 my-md-3`}>
                            <span className={`${style.size}`}>XS<span className={` ${item.productsizes.indexOf('xs')!==-1?'':style.notavailable}`}><RxCross1></RxCross1> </span></span>
                            <span className={`${style.size}`}>S <span className={` ${item.productsizes.indexOf('s')!==-1?'':style.notavailable}`}><RxCross1></RxCross1></span></span>
                            <span className={`${style.size}`}>M <span className={` ${item.productsizes.indexOf('m')!==-1?'':style.notavailable}`}><RxCross1></RxCross1></span></span>
                            <span className={`${style.size}`}>L <span className={` ${item.productsizes.indexOf('l')!==-1?'':style.notavailable}`}><RxCross1></RxCross1></span></span>
                            <span className={`${style.size} `}>XL<span className={`${item.productsizes.indexOf('xl')!==-1?'':style.notavailable}`}><RxCross1></RxCross1></span></span>
                        </div>
                    </div>
                </div>
                <div className={`${style.itemdescription} col-12 col-md-6 my-5 my-md-0 `}>
                    <h3>{item.name}</h3>
                    <span >{item.description}</span>
                </div>
            </div>

            <div className={`d-flex flex-column ${style.recommendation} ${relatedItem.length === 0?'d-none':''} col-12`}>
                <span className='col-12'><h3 className='col-12'>You may also like</h3></span>
                <div className={`${style.recommendeditems} col-12`}>
                        {
                            relatedItem.map((item,index)=>index<4?<Item item={item}></Item>:'')
                        }
                    </div>
            </div>
        </div>
    )
}