// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDSw3Wkz5_rJPY9XB1cNtwhwdMyj77NLqI",
  authDomain: "rinzin-bawa-286e6.firebaseapp.com",
  projectId: "rinzin-bawa-286e6",
  storageBucket: "gs://rinzin-bawa-286e6.appspot.com",
  messagingSenderId: "498522020473",
  appId: "1:498522020473:web:96c33ce706a3b5c24bd2a8",
  measurementId: "G-LVERQHR6S3",
  databaseURL:"https://console.firebase.google.com/project/rinzin-bawa-286e6/database/rinzin-bawa-286e6-default-rtdb/data/~2F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export {db,storage};