import style from './Contactus.module.css'
export default function Contactus(){
    return(
        <div className={`${style.contactuscontainer} d-flex`}>
            <div className={`${style.information} col-12 col-md-6 d-flex flex-column align-items-end justify-content-center`}>
                <h3 className='col-6'>Addresss</h3>
                <br></br>
                <span className='col-6'>Name of the company</span>
                <span className='col-6'>Location </span>
                <br></br>
                <br></br>
                <h3 className='col-6'>Information</h3>
                <br></br>
                <span className='col-6'>Name of the company</span>
                <span className='col-6'>Location </span>
            </div>
            <div className={`${style.location} col-12 col-md-6 d-flex align-items-center justify-content-center`}>
                <h3> This is google map area</h3>
            </div>
        </div>
    )
}